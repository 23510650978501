0
<template>
    <div>
        <div class="main-title">海报列表</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head">
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">轮播图大小为：920*360</li>
                        <li data-v-69874ad0="">小程序和H5展示</li>
                    </ul>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l">
                    <el-select v-model="searchData.type" clearable placeholder="选择类型" size="mini">
                        <el-option label="课程" :value="1"></el-option>
                        <el-option label="课程计划" :value="2"></el-option>
                    </el-select>
                </span>

                <span class="margin-l-sm fl-l">
                    <el-select v-model="searchData.status" clearable placeholder="选择状态" size="mini">
                        <el-option label="禁用" :value="0"></el-option>
                        <el-option label="启用" :value="1"></el-option>
                    </el-select>
                </span>
                <span class="margin-l-sm fl-l">
                    <el-input v-model="searchData.name" size="mini" placeholder="模板名称"></el-input>
                </span>


                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class=" fl-r">
                    <router-link to="/poster/add" class="router-link">
                        <el-button type="primary" size="small">新增</el-button>
                    </router-link>
                </span>

                <div style="clear: both"></div>

            </div>


            <div class="table-content">

                <el-table :header-cell-style="{padding:0}" ref="singleTable" :data="tableData" highlight-current-row
                    v-loading="loading" style="width: 100%;background: #fff;">
                    <el-table-column label="ID" property="id" width="80">
                    </el-table-column>
                    <el-table-column property="name" label="名称">
                    </el-table-column>
                    <el-table-column label="图片">
                        <template slot-scope="scope">
                            <el-image class="table-item-img " :src="imageUrl+scope.row.pic"
                                :preview-src-list="[imageUrl+scope.row.pic]">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </template>
                    </el-table-column>

                    <el-table-column property="created_at" label="创建时间">
                    </el-table-column>

                    <el-table-column property="index" label="类型">
                        <template slot-scope="scope">
                            <span>{{scope.row.type == 1 ? ' 课程':'课程计划'}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column property="address" label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status == 1" class="state-up"><i></i>已上架</span>
                            <span v-else-if="scope.row.status == 0" class="state-down"><i></i>已下架</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="address" label="操作">
                        <template slot-scope="scope">
                            <span>
                                <el-link slot="reference" type="primary" @click="editDetail(scope.row)">编辑</el-link>
                            </span> -
                            <span>
                                <el-link v-if="scope.row.status == 1" type="primary"
                                    @click="statusSet(scope.row,scope.$index)">下架</el-link>
                                <el-link v-if="scope.row.status == 0" type="primary"
                                    @click="statusSet(scope.row,scope.$index)">上架</el-link>
                            </span> -
                            <span>
                                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                    @confirm="deleted(scope.row)">
                                    <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch">
                <span class="fl-r">
                    <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize"
                        @current-change="setPage" :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>


    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "list",
    data() {
        return {
            page: 1,
            pageSize: 10,
            total: 0,
            loading: true,
            imageUrl: config.imageUrl,
            searchData: {},

            tableData: [],
        }
    },
    methods: {
        ...mapActions('poster', [
            'getPosterList',
            'delTemplate'
        ]),
        ...mapActions('common', ['setStatus']),




        search() {
            this.tableData = []
            this.page = 1
            this.getList()
        },
        async deleted(row, rowIndex) {
            const { data, res_info } = await this.delTemplate(row.id)
            if (res_info != 'ok') return
            this.$message.success('删除成功')
            this.getList()
        },
        editDetail(row) {
            this.$router.push('/poster/edit/' + row.id)
        },
        statusSet(row, rowIndex) {
            let status = row.status == 1 ? 0 : 1
            let data = {
                id: row.id,
                status: status,
                model: 'Poster\\TemplateModel'
            }
            this.setStatus(data).then(res => {
                if (res.ret === 0) {
                    this.$message.success('设置成功')
                    this.getMomentData()
                    this.getList()
                }
            })
        },
        getList() {
            this.loading = true
            let data = {
                page: this.page,
                pageSize: this.pageSize,
                ...this.searchData,
            }
            this.getPosterList(data).then(res => {
                this.loading = false
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },
        setPageSize(pageSize) {
            this.pageSize = pageSize
            this.getList()
        },
        setPage(page) {
            this.page = page
            this.getList()
        },
        typeName(type) { }

    },
    mounted() {
        this.getList()
    }
}
</script>

<style scoped>

</style>
